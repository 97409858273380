import React from "react"
import styled from "styled-components"
import Boxed from "../../bits/box"
import Img from "gatsby-image"

const Section = styled.div`
  min-height: 50vh;
  background-color: ${props => (props.color ? `${props.color}` : "black")};
  padding: var(--padding-m) var(--padding-m) 0 var(--padding-m);
`

const Subtitle = styled.h6`
  color: var(--color-white-500);
  z-index: 100;
`

const Title = styled.h1`
  margin: 0;
  color: var(--color-white-500);
  z-index: 100;
`

const Image = styled(Img)`
  background-color: rgba(0, 0, 0, 0);

  @media only screen and (max-width: 1024px) {
    margin-top: 30px;
    //position: absolute !important;
    //width: 80%;
    //max-height: 800px;
    //bottom: 0;
    //z-index: 1;
  }
`

const WorkHero = ({ data }) => {
  return (
    <Section className="full-bleed" color={data.color}>
      <Boxed size="large">
        <Subtitle>{data.title}</Subtitle>
        <Title>{data.subtitle}</Title>
        <Image fluid={data.cover.childImageSharp.fluid} alt={data.title} />
      </Boxed>
    </Section>
  )
}

export default WorkHero
