import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Boxed from "../bits/box"
import Layout from "./index"
import WorkHero from "../components/WorkHero/workHero"
import SEO from "../utils/seo"

const WorkWrapper = styled(Boxed)`
  max-width: 80vw;

  @media only screen and (max-width: 768px) {
    max-width: 90vw;
  }
`

const MDX = styled(MDXRenderer)`
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: var(--font-primary);
  }
`

const WorkPage = ({ pageContext, data }) => {
  const postEdges = data.mdx.frontmatter

  return (
    <Layout>
      <SEO title="Use Cases" />
      <WorkHero data={postEdges} />
      <WorkWrapper>
        <MDX>{data.mdx.body}</MDX>
      </WorkWrapper>
    </Layout>
  )
}

export default WorkPage

export const pageQuery = graphql`
  query WorkPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      excerpt
      frontmatter {
        title
        subtitle
        path
        tags
        color
        cover {
          publicURL
          childImageSharp {
            fluid(maxHeight: 1200) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`
